import React, { useState, useEffect, useRef } from "react";
import { ChevronDown, ChevronUp, Check } from "react-feather";

interface CustomDropdownProps {
  options: string[];
  onSelect: (option: string) => void;
  selectedOption: string;
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({
  options,
  onSelect,
  selectedOption,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleOptionClick = (option: string) => {
    onSelect(option);
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        className="flex w-full items-center justify-between rounded-lg border border-stone-800 bg-white px-3 py-2 text-left drop-shadow-sm"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{selectedOption}</span>
        {isOpen ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
      </button>
      {isOpen && (
        <div className="absolute z-10 mt-2 w-full rounded border border-gray-300 bg-white shadow">
          {options.map((option) => (
            <div
              key={option}
              className="flex cursor-pointer items-center justify-between px-4 py-2 hover:bg-gray-200"
              onClick={() => handleOptionClick(option)}
            >
              <span>{option}</span>
              {option === selectedOption && <Check size={16} />}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
