import axios from "axios";

const { REACT_APP_API_URL } = process.env;

export const fetchParticipants = async () => {
  const response = await axios.get(`${REACT_APP_API_URL}/admin/participants`);
  return response.data.data;
};

export const deleteParticipant = async (id: string) => {
  await axios.delete(`${REACT_APP_API_URL}/admin/participant/${id}`);
};

export const fetchConfig = async () => {
  const response = await axios.get(`${REACT_APP_API_URL}/admin/config`);
  return response.data;
};

export const saveConfig = async (configData: {
  isActive: boolean;
  startDate: string;
  endDate: string;
  set: number;
}) => {
  const response = await axios.post(
    `${REACT_APP_API_URL}/admin/config`,
    configData,
  );
  return response.data;
};
