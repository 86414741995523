import React, { useState, useEffect } from "react";
import { Table, Button, DatePicker, Popconfirm, message } from "antd";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment-timezone";
import { fetchParticipants, deleteParticipant } from "../api/api";
import { Trash } from "react-feather";

interface LeaderboardData {
  _id: string;
  name: string;
  email: string;
  phoneNumber: string;
  score: number;
  totalTimeTaken: number;
  createdAt: string;
}

const QuizData: React.FC = () => {
  const [leaderboardData, setLeaderboardData] = useState<LeaderboardData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedDate, setSelectedDate] = useState<string>("");

  const fetchData = async (date?: string) => {
    setLoading(true);
    const data = await fetchParticipants();
    if (date) {
      const filteredData = data.filter((item: LeaderboardData) =>
        moment(item.createdAt).isSame(date, "day"),
      );
      setLeaderboardData(filteredData);
    } else {
      setLeaderboardData(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedDate) {
      fetchData(selectedDate);
    } else {
      fetchData();
    }
  }, [selectedDate]);

  const handleDelete = async (id: string) => {
    try {
      await deleteParticipant(id);
      message.success("Participant deleted successfully");
      fetchData(selectedDate);
    } catch (error) {
      message.error("Failed to delete participant");
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Score",
      dataIndex: "score",
      key: "score",
    },
    {
      title: "Total Time Taken",
      dataIndex: "totalTimeTaken",
      key: "totalTimeTaken",
    },
    {
      title: "Timestamp",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: string) =>
        moment(createdAt).tz("Asia/Kolkata").format("DD-MM-YYYY hh:mm:ss A"),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_text: any, record: LeaderboardData) => (
        <Popconfirm
          title="Are you sure you want to delete?"
          onConfirm={() => handleDelete(record._id)}
          okText="Yes"
          cancelText="No"
          overlayStyle={{ marginLeft: "-10px" }}
        >
          <Trash size={20} className="cursor-pointer text-red-600" />
        </Popconfirm>
      ),
    },
  ];

  const handleDownloadExcel = () => {
    const headers = [
      { label: "Name", key: "name" },
      { label: "Email", key: "email" },
      { label: "Phone Number", key: "phoneNumber" },
      { label: "Score", key: "score" },
      { label: "Total Time Taken", key: "totalTimeTaken" },
      { label: "Timestamp", key: "createdAt" },
    ];

    const filteredDataWithHeaders = leaderboardData.map((item) => {
      const filteredItem: { [key: string]: any } = {};
      headers.forEach((header) => {
        if (header.key === "createdAt") {
          filteredItem[header.label] = moment(item[header.key])
            .tz("Asia/Kolkata")
            .format("DD-MM-YYYY hh:mm:ss A");
        } else {
          filteredItem[header.label] =
            item[header.key as keyof LeaderboardData];
        }
      });
      return filteredItem;
    });

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(filteredDataWithHeaders);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");

    const excelBuffer = XLSX.write(workbook, {
      type: "array",
      bookType: "xlsx",
    });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(data, "FlipkartData.xlsx");
  };

  return (
    <div>
      <div className="title-csv my-4 items-center justify-between px-6 sm:flex">
        <h2 className="mb-3 font-semibold sm:mb-0">
          Flipkart Phone Power Hour Data
        </h2>
        <div className="flex items-center space-x-4">
          <DatePicker
            onChange={(date, dateString) => {
              setSelectedDate(
                Array.isArray(dateString) ? dateString[0] : dateString,
              );
              if (!date) fetchData();
            }}
          />
          <Button onClick={handleDownloadExcel} style={{ fontWeight: 600 }}>
            Download Excel
          </Button>
        </div>
      </div>
      <div className="flex-1 overflow-auto px-6">
        <Table
          className="table"
          dataSource={leaderboardData.map((item, index) => ({
            ...item,
            key: index,
          }))}
          columns={columns}
          loading={loading}
          pagination={{ pageSize: 5 }}
          scroll={{ x: "max-content" }}
        />
      </div>
    </div>
  );
};

export default QuizData;
