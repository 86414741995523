import React from "react";

const { REACT_APP_WORKPLAY_URL, REACT_APP_S3_URL } = process.env;

const Navbar: React.FC = () => {
  return (
    <div className="flex items-center bg-[#1e1e1e] p-6">
      <a
        href={`${REACT_APP_WORKPLAY_URL}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="h-5 lg:h-7"
          src={`${REACT_APP_S3_URL}/assets/wp-logo.png`}
          alt="workplay-logo"
        />
      </a>
    </div>
  );
};

export default Navbar;
