import React, { useEffect, useState } from "react";
import Schedule from "../components/Schedule";
import QuizData from "../components/QuizData";

const HomePage: React.FC = () => {
  const [activeSection, setActiveSection] = useState<"schedule" | "viewData">(
    () =>
      (localStorage.getItem("activeSection") as "schedule" | "viewData") ||
      "schedule",
  );

  useEffect(() => {
    localStorage.setItem("activeSection", activeSection);
  }, [activeSection]);

  return (
    <div className="w-full text-left">
      <div className="relative mt-4 flex px-6">
        <div
          className={`w-44 cursor-pointer rounded-l-lg border-2 px-4 py-2 text-center text-sm sm:text-base ${activeSection === "schedule" ? "z-10 border-black bg-black font-semibold text-white" : "z-0 border-stone-200 bg-gray-200"}`}
          onClick={() => setActiveSection("schedule")}
          style={{ borderRight: "1px solid #000" }}
        >
          Schedule Event
        </div>
        <div
          className={`w-44 cursor-pointer rounded-r-lg border-2 px-4 py-2 text-center text-sm sm:text-base ${activeSection === "viewData" ? "z-10 border-black bg-black font-semibold text-white" : "z-0 border-stone-200 bg-gray-200"}`}
          onClick={() => setActiveSection("viewData")}
          style={{ borderLeft: "1px solid #000" }}
        >
          View Data
        </div>
      </div>
      <div className="mt-6">
        {activeSection === "schedule" && <Schedule />}
        {activeSection === "viewData" && <QuizData />}
      </div>
    </div>
  );
};

export default HomePage;
