import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomDropdown from "./CustomDropdown";
import "../styles/Toggle.css";
import { fetchConfig, saveConfig } from "../api/api";
import moment from "moment-timezone";
import toast from "react-hot-toast";

const Schedule: React.FC = () => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [selectedSet, setSelectedSet] = useState<string>("");

  useEffect(() => {
    const getConfig = async () => {
      try {
        const { data } = await fetchConfig();
        const start = moment(data.startDate).toDate();
        const end = moment(data.endDate).toDate();
        setStartDate(start);
        setEndDate(end);
        setIsActive(data.isActive);
        setSelectedSet(`Set ${data.set}`);
      } catch (error) {
        console.error("Failed to fetch config:", error);
      }
    };
    getConfig();
  }, []);

  const handleToggle = () => {
    setIsActive(!isActive);
  };

  const handleDropdownSelect = (option: string) => {
    setSelectedSet(option);
  };

  const handleSave = async () => {
    if (startDate && endDate) {
      const istStartDate = moment.tz(startDate, "Asia/Kolkata").format();
      const istEndDate = moment.tz(endDate, "Asia/Kolkata").format();
      const utcStartDate = moment.tz(startDate, "Asia/Kolkata").utc().format();
      const utcEndDate = moment.tz(endDate, "Asia/Kolkata").utc().format();
      const setNumber = selectedSet === "Set 1" ? 1 : 2;

      const payload = {
        isActive,
        startDate: utcStartDate,
        endDate: utcEndDate,
        set: setNumber,
      };

      try {
        await saveConfig(payload);
        toast.success("Schedule saved successfully");
      } catch (error) {
        toast.error("Failed to save schedule");
        console.error("Failed to save config:", error);
      }
    }
  };

  return (
    <div className="w-full rounded-lg bg-white p-4 drop-shadow sm:ml-6 xl:w-[24%]">
      <div className="mb-4">
        <label className="mb-2 block font-bold text-gray-700">
          Start Date and Time
        </label>
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          showTimeSelect
          dateFormat="Pp"
          className="w-full rounded-lg border border-gray-300 px-4 py-2"
          timeFormat="HH:mm"
        />
      </div>
      <div className="mb-4">
        <label className="mb-2 block font-bold text-gray-700">
          End Date and Time
        </label>
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          showTimeSelect
          dateFormat="Pp"
          className="w-full rounded-lg border border-gray-300 px-4 py-2"
          timeFormat="HH:mm"
        />
      </div>
      <div className="mb-4 flex items-center justify-between">
        <label className="mb-2 block font-bold text-gray-700">Active</label>
        <label className="toggle-switch">
          <input type="checkbox" checked={isActive} onChange={handleToggle} />
          <span className="slider"></span>
        </label>
      </div>
      <div className="mb-4">
        <label className="mb-2 block font-bold text-gray-700">Select Set</label>
        <CustomDropdown
          options={["Set 1", "Set 2"]}
          selectedOption={selectedSet}
          onSelect={handleDropdownSelect}
        />
      </div>
      <div className="flex items-center justify-end">
        <button
          onClick={handleSave}
          className="mt-4 rounded bg-black px-4 py-2 text-white"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default Schedule;
